import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { HttpClientModule } from "@angular/common/http";
import { FlexModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from "./cookie-banner-config";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatProgressBarModule,
    HttpClientModule,
    FlexModule,
    RouterModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
    exports: [
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
