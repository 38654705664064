import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { childRoutes } from "./childroutes";
import { BuildSiteComponent } from "./shared/build-site/build-site.component";

const routes: Routes = [
  ...childRoutes,
  {
    path: "**",
    pathMatch: 'full',
    component: BuildSiteComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
