import {NgcCookieConsentConfig} from "ngx-cookieconsent";
export const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": window.location.hostname
  },
  "position": "bottom-left",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000db",
      "text": "#ffffff",
      "link": "#1d87f9"
    },
    "button": {
      "background": "#005cea",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "We use cookies on this site to provide necessary functionality, enhance your user experience and analyze our traffic. By using our website, you agree to our",
    "dismiss": "Accept",
    "deny": "",
    "link": "Privacy Policy",
    "href": "https://fossity.com/legal/privacy",
    "policy": "Cookie Policy"
  }
}
