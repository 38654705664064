import { Routes } from "@angular/router";
export const childRoutes: Routes = [

  {
    path: "internal",
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: "newsletter",
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: "",
    data: {text: "home"},
    loadChildren: () => {
      return import("./home/home.module").then((m) => m.HomeModule)
    },
  },
  {
    path: "legal",
    data: {text: "legal"},
    loadChildren: () => {
      return import("./legal/legal.module").then((m) => m.LegalModule)
    },
  },
  {

    path: "status/:id",
    data: {text: "status"},
    loadChildren: () => {
      return import("./status/status.module").then((m) => m.StatusModule)
    },
  }
];
